import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawLabourPage: React.FC = () => (
  <Layout id="lawLabour">
    <SEO title="LabourLaw" />
    <LawPage
      law="LawLabour"
      title="fieldLawLabour"
      serviceTitle="fieldLawLabourTitle"
      lawTexts={[
        'fieldLawLabourOne',
        'fieldLawLabourTwo',
        'fieldLawLabourThree',
        'fieldLawLabourFour',
        'fieldLawLabourFive',
        'fieldLawLabourSix',
        'fieldLawLabourSeven',
        'fieldLawLabourEight',
      ]}
    />
  </Layout>
);

export default LawLabourPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
